import React, { useEffect, useState } from "react";
import "./App.css";
import EquilawLogo from "./EquilawLogo.svg";
import { Box, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import axios from "axios";

function App() {
  const [searchParams] = useSearchParams();

  const [confirmedEmail, setConfirmedEmail] = useState<string>("");

  useEffect(() => {
    const matterId = searchParams.get("matter_id");
    const confirmed = searchParams.get("confirmed");
    setConfirmedEmail(confirmed || "No");
    const email = searchParams.get("email");

    console.log(email, confirmed, matterId);

    if (matterId !== "" && confirmed !== "" && email !== "") {
      confirmEmail(confirmed || "No", email || "", parseInt(matterId || "0"));
    }
  }, [searchParams]);

  const confirmEmail = async (
    confirmed: string,
    email: string,
    matterId: number
  ) => {
    await axios.post("https://forms-prod.novex.software/confirm-email", {
      confirmed: confirmed,
      email: email,
      matter_id: matterId,
    });
  };

  return (
    <Box>
      <Box minHeight="15vh">
        <Box marginLeft={20} marginTop={2}>
          <a href="https://equilaw.uk.com">
            <img
              alt="Equilaw"
              style={{ height: 100, width: 250 }}
              src={EquilawLogo}
            />
          </a>
        </Box>
      </Box>
      <Box
        textAlign={"center"}
        minHeight="85vh"
        color="white"
        bgcolor="#00395a"
      >
        <Box paddingLeft={10} paddingRight={10} paddingTop={30}>
          <Typography variant="h3">
            {confirmedEmail === "Yes" &&
              "Thank you for confirming your email address."}
            {confirmedEmail === "No" &&
              "Thank you for confirming your email address is incorrect."}
          </Typography>
          <br />
          {confirmedEmail === "No" && (
            <>
              <Typography variant="h3">
                If you are a third party, please call us on this number:
              </Typography>
              <Typography variant="h3">01452 657999</Typography>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default App;
